import React from 'react'

import { Link } from 'react-router-dom'

function Header(){

    return(
        <div className={"header"}>
            <Link to="/" className={"lien-header rotate"}>
                    <img className={"logo"} alt={"studio.md.creation"} src={"/images/logo.svg"} />
            </Link>
            <a href="/infos" className={"lien-header lien-contact"}>
                <div>
                    <span className={"texte-bold"}>à propos</span>
                </div>
            </a>
        </div>
    )
}

export default Header

import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Header from './components/Header'
import Home from './components/Home'
import Projet from './components/Projet'

function App() {
  return (
    <div class="root-conteneur">
      <BrowserRouter>
        <Header/>
          <Routes>
              <Route exact path="/" element={<Home />}/>
              <Route path="/projet/:project" element={<Projet />}/>
          </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;

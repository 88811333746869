import React from "react";

const Detail = ({titre, valeur}) => {
    return <div className={"projet-info"}>
        <h3 className={"texte-regular projet-soustitre"}>{titre}</h3>
        <span className={"projet-valeur"}>{valeur}</span>
    </div>
}

export default Detail

import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import flechegauche from '../assets/flechegauche.png'
import flechedroite from '../assets/flechedroite.png'
import rond from '../assets/rond.png'

const NeighborsProjects = ({left,right}) => {
    const leftCpt = left ? (
    <Link to={`/projet/${left}`} style={{color:'black'}}>
        <Wrap exist>
            <Fleche className={"gauche"} src={flechegauche}/>
        </Wrap>
    </Link>
    ):(
        <Link to={''} onClick={event => event.preventDefault()} style={{cursor: 'default', color:'black'}}>
            <Wrap>
                <Fleche className={"gauche"} src={flechegauche}/>
            </Wrap>
        </Link>
    )
    const rightCpt = right ? (
    <Link to={`/projet/${right}`} style={{color:'black'}}>
        <Wrap exist>
            <Fleche src={flechedroite}/>
        </Wrap>
    </Link>
    ):(    
    <Link to={''} onClick={event => event.preventDefault()} style={{cursor: 'default', color:'black'}}>
        <Wrap>
            <Fleche src={flechedroite}/>
        </Wrap>
    </Link>
    )

    return(
        <div className={"nav-conteneur"}>
            <Outer>
                {leftCpt}        
                {rightCpt}
            </Outer>
            <Link to={'/'}>
                <div className={"conteneur-rond"}>
                    <img className={"rond"} src={rond}/>
                </div>
            </Link>
        </div>
    )
}

const Fleche = ({ src, className }) => <img className={`fleche ${className}`} src={src}/>

const Outer = ({children}) => <div className={"conteneur-navproj"}>{children}</div>

const Wrap = ({ children, exist }) => <div className={`conteneur-fleche ${exist?"":"noexist"}`}>{children}</div>

export default NeighborsProjects

import React from 'react';
import styled from 'styled-components'

function Panel(props){
    return(
        <Container left={props.left} odd={props.odd} width={props.width} padding={props.padding}>
                {props.children}
        </Container>
    )
}

const Container = styled.div`
    ${props => props.left ? 'border-right: 3px solid black;':'border-left: 3px solid black;'}
    scrollbar-width:0px;
    min-width: 50%;
    padding: 0;
    box-sizing: border-box;
    position: relative;
    overflow-y: auto;
    -ms-overflow-style: none;  /* hide scrollbars IE and Edge */
    scrollbar-width: none;  /* hide scrollbars Firefox */

    &::-webkit-scrollbar {
      display: none; /* Hide scrollbar for Chrome, Safari and Opera */
    }

    @media (max-width: 768px){
        overflow-y: auto;
        width: 100%;
        
    }
    ${props => props.left ? `
        @media (max-width: 768px){
            overflow-y: auto;
            width: 100%;
            border-right: 0px;
        }
        
        & > a:nth-child(${props.odd? 'odd':'even'}){
            @media (min-width: 769px) {
                display: none;
            }
        }
        `
        :
        `
        @media (max-width: 768px){
            display: none;
        }

        & > a:nth-child(${props.odd? 'odd':'even'}){
            @media (min-width: 769px) {
                display: none;
            }
        }
    `}
`

export default Panel

import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import Panel from './Panel'
import Container from './Container'


function Home(){
    const [donnees, setDonnees] = useState(null)

    useEffect(() => {
        fetch('donnees.json',
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            })
            .then(response => response.json())
            .then(result => {
                setDonnees(result)
            })
    }, []);

    const photosaccueil = donnees ? Object.keys(donnees.projects).map((key) => {
        return(<Link key={key} to={`/projet/${key}`}>
            <PhotoContainer>
                <Photo src={`images/accueil/${key}01.jpg`}/>
            </PhotoContainer>
        </Link>)
    }) : null;

    return(
        <Container>
            <Panel left>
                {photosaccueil}
            </Panel>
            <Panel odd>
                {photosaccueil}
            </Panel>
        </Container>
    )
}

const PhotoContainer = styled.div`
    display: flex;
    position: relative;
    width: 100%;
    border-bottom : 6px solid black;
`

const Photo = styled.img`
    width: 100%;
`

export default Home

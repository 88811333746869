import React from 'react'
import ImageLoader from 'react-load-image'
import styled from 'styled-components'
import fusee from '../assets/fusee.png'

function Image(props){
    return(<ImageLoader src={props.src}>
        <Img/>
        <Conteneur>
            <span>Erreur !</span>
        </Conteneur>
        <Conteneur><img src={fusee} style={{height:'30%'}} alt='Un peu de patience, ça charge !'/> </Conteneur>  
    </ImageLoader>)
}

const Conteneur = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: AlegreyaSans;
    height: 50vh;
    font-size: 3em;
`

const Img = styled.img`
    width: 100%;
`

export default Image
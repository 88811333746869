import styled from 'styled-components'

const Container = styled.div`
    display: flex;
    flex-direction: row;
    margin: 0;
    width: 100%;
    overflow-y: hidden;
    flex-grow: 2;
    padding: 0;
` 

export default Container
import React, { useState, useEffect, useRef } from 'react'
import NeighborsProjects from './NeighborsProjects'
import Image from './Image'
import {useParams} from "react-router-dom";
import Detail from "./projet-components/Detail";

function Projet(){
    const [donnees, setDonnees] = useState(null)
    const { project } = useParams()

    useEffect(() => {
        fetch('/donnees.json',
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            })
            .then(response => response.json())
            .then(result => {
                const idx = Object.keys(result.projects).indexOf(project)
                const res = result.projects[project]
                res.left = Object.keys(result.projects)[idx-1]
                res.right = Object.keys(result.projects)[idx+1]
                setDonnees(res)
            })
    },[project]);

    if(!donnees){        
        return <div className={"projet-conteneur"}></div>
    }
    else{
        const titre = <h2 className={"projet-titre"}>{donnees.name}</h2>
        const client = donnees.client && <Detail titre={"client"} valeur={donnees.client}/>
        const annees = donnees.annee && <Detail titre={"années"} valeur={donnees.annee}/>
        const categorie = donnees.categorie && <Detail titre={"catégorie"} valeur={donnees.categorie}/>
        const paragraph = <p className={"projet-paragraph texte-regular"}>{donnees.commentaire}</p>

        const images = donnees.photos.map((value,key)=>{
            return(<div className={"conteneur-photo projet"} key={key}>
                <Image src={value}/>
            </div>)
        })

        return (<div className={"projet-conteneur"}>
            <div className={"projet-wrap"}>
                {titre}
                {client}
                {categorie}
                {annees}
                {paragraph}
                <NeighborsProjects left={donnees.left} right={donnees.right}/>
            </div>
            <PhotoWrap>
                <div head className={"texte-thin projet-wrap head"}>
                    {titre}
                    {client}
                    {categorie}
                    {annees}
                    {paragraph}
                    <NeighborsProjects left={donnees.left} right={donnees.right}/>
                </div>
                {images}
            </PhotoWrap>
        </div>)
    }    
}

function PhotoWrap(props){
    const [ypos,setYpos] =  useState(0)
    const containerRef = useRef(null)
    
    const scrollHandler = (e) => {
        let tmp = 200
        if(e.deltaY < 0) tmp = -200 
        let newPos = ypos + tmp
        if(newPos > containerRef.current.scrollHeight-containerRef.current.offsetHeight) 
            newPos = containerRef.current.scrollHeight-containerRef.current.offsetHeight
        else if(newPos < 0) newPos = 0
        containerRef.current.scroll(0,newPos)
        setYpos(newPos)
    }

    return(
        <div className={"panneau right projet"} ref={containerRef} onWheel={scrollHandler}>
                {props.children}
        </div>
    )
}

export default Projet
